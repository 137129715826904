<template>
  <div class="register">
    <div @click="goBack" class="toback">
      <i class="el-icon-d-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="reg-header">社会化服务平台</div>
    <div class="mainContent">
      <div class="mc-header">
        <div
          v-for="(item, index) in arrayList"
          :key="index"
          @click="changeDetail(item, index)"
          :class="detailShowInex == index ? 'active_div' : ''"
        >
          {{ item.label }}
        </div>
      </div>
      <serviceSubject v-if="detailShowInex == 0" />
      <villageCollective v-if="detailShowInex == 1" />
      <bodyProduction v-if="detailShowInex == 2" />
    </div>
  </div>
</template>
<script>
import serviceSubject from "./serviceSubject.vue";
import bodyProduction from "./bodyProduction.vue";
import villageCollective from "./villageCollective.vue";
export default {
  components: {
    serviceSubject,
    bodyProduction,
    villageCollective,
  },
  data() {
    return {
      detailShowInex: 0,
      arrayList: [
        { label: "服务主体", value: 0 },
        { label: "村集体", value: 1 },
        { label: "生产主体", value: 2 },
      ],
    };
  },
  methods: {
    changeDetail(item, index) {
      this.detailShowInex = index;
    },
    goBack() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  .toback {
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    margin-top: 6px;
    font-size: 14px;
    color: #626262;
  }
  width: 100%;
  .reg-header {
    width: calc(100% - 20px);
    height: 60px;
    line-height: 60px;
    background-color: #17a763;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 10px;
  }
  .mainContent {
    width: 1396px;
    margin: 10px auto;
    .mc-header {
      border: 1px solid #ddd;
      height: 55px;
      line-height: 55px;
      margin-top: 30px;
      background-color: #f2f2f2;
      display: flex;
      div {
        width: 132px;
        text-align: center;
        line-height: 55px;
        height: 55px;
        font-size: 16px;
        color: #333;
        cursor: pointer;
        border-right: 1px solid #ddd;
      }
      .active_div {
        color: #17a763;
        font-weight: bold;
        border-bottom: 2px solid #17a763;
      }
    }
  }
}
</style>