<template>
  <div class="serviceSubject">
    <div class="formContent">
      <div class="fc-title">
        <span class="fct-text">基本信息</span>
      </div>
      <el-form
        inline
        :model="formData"
        label-width="130px"
        label-position="right"
      >
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>主体名称</span>
              </div>
            </template>
            <el-input
              v-model="formData.companyName"
              placeholder="请输入主体名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>组织类型</span>
              </div>
            </template>
            <el-select v-model="formData.orgType" placeholder="请选择组织类型">
              <el-option
                v-for="item in typeList"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>联系人姓名</span>
              </div>
            </template>
            <el-input
              v-model="formData.contacts"
              placeholder="请输入联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item class="phoneItem">
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>联系人电话</span>
              </div>
            </template>
            <el-input
              v-model="formData.phone"
              placeholder="请输入联系人电话"
            ></el-input>
            <el-input v-model="code" placeholder="请输入验证码"></el-input>
            <el-button v-if="!isSendCode" @click="sendCode"
              >发送验证码</el-button
            >
            <el-button v-if="isSendCode">{{
              `重新发送(${sendTime})`
            }}</el-button>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>法人代表</span>
              </div>
            </template>
            <el-input
              v-model="formData.legalPerson"
              placeholder="请输入法人代表"
            ></el-input>
          </el-form-item>
        </div>
        <div class="selectContent">
          <el-form-item label="所在区域">
            <el-select
              @change="dealPlace(1)"
              v-model="formData.cityCode"
              placeholder="市"
            >
              <el-option
                v-for="item in cityList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select
              @change="dealPlace(2)"
              v-model="formData.countyCode"
              placeholder="区/县"
            >
              <el-option
                v-for="item in areaList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select
              @change="dealPlace(3)"
              v-model="formData.townCode"
              placeholder="乡/镇"
            >
              <el-option
                v-for="item in streetCity"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select v-model="formData.villageCode" placeholder="村">
              <el-option
                v-for="item in villageList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>经营状态</span>
              </div>
            </template>
            <el-radio v-model="formData.runState" label="1">正常</el-radio>
            <el-radio v-model="formData.runState" label="0">异常</el-radio>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div v-if="isShowOther">
      <div class="formContent">
        <div class="fc-title">
          <span class="fct-text">主体情况</span>
        </div>
        <el-form
          inline
          label-position="right"
          :model="formData"
          label-width="130px"
        >
          <div>
            <el-form-item>
              <template #label>
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>营业执照</span>
                </div>
              </template>
              <imageUpload :limit="1" v-model="formData.picUrl" />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>服务品种</span>
                </div>
              </template>
              <el-checkbox-group
                v-model="checkedTypeArray"
                @change="changeServiceType"
              >
                <el-checkbox
                  v-for="item in serviceTypeArray"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>服务类型</span>
                </div>
              </template>
              <el-checkbox-group v-model="checkedVarietyList">
                <el-checkbox
                  v-for="item in serviceVarietyList"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="fc-box">
            <el-form-item label="主体荣誉介绍">
              <editor v-model="workCapacityDesc" :min-height="192" />
            </el-form-item>
          </div>
          <div>
            <el-form-item label="主体Logo">
              <imageUpload
                :limit="1"
                v-model="cmsOrganizationCapacity.identityCardFrontUrl"
              />
            </el-form-item>
            <el-form-item label="主体图片">
              <imageUpload
                :limit="1"
                v-model="cmsOrganizationCapacity.longitude"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="formContent">
        <div class="fc-title">
          <span class="fct-text">服务情况</span>
        </div>
        <el-form
          inline
          :model="cmsOrganizationCapacity"
          label-width="140px"
          label-position="right"
        >
          <div class="fc-box">
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>从业人数</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.staffNum"
                maxlength="10"
              >
                <template #suffix>人</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>年服务营业收入</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.turnoverYear"
                maxlength="10"
              >
                <template #suffix>万元</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="fc-box">
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>服务对象数</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.serviceObjectNum"
                maxlength="10"
              >
                <template #suffix>个</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>服务小农户数</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.serviceSmallFarmersNum"
                maxlength="10"
              >
                <template #suffix>个</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="fc-box">
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>年服务面积</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.serviceAreaNum"
                maxlength="10"
              >
                <template #suffix>亩</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <template slot="label">
                <div class="lb-slot">
                  <span style="color: red; margin-right: 3px">*</span>
                  <span>其他行业服务总量</span>
                </div>
              </template>
              <el-input
                v-model.number="cmsOrganizationCapacity.serviceOtherYear"
                maxlength="10"
              >
                <template #suffix>个</template>
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="footerBtn">
      <el-button
        v-if="!isShowOther"
        @click="checkRule"
        type="success"
        icon="el-icon-position"
        >快速注册</el-button
      >
      <el-button
        @click="checkRule"
        v-if="isShowOther"
        type="success"
        icon="el-icon-check"
        >申报注册</el-button
      >
      <el-button type="primary" @click="resetForm" icon="el-icon-refresh"
        >重置</el-button
      >
      <el-button v-if="!isShowOther" @click="isShowOther = true" type="info"
        >申报注册 ⬇</el-button
      >
      <el-button v-if="isShowOther" @click="isShowOther = false" type="info"
        >快速注册 ⬆</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  getPlace,
  sendPhoneVeriCode,
  getDictData,
  addOrganization,
  serviceHall,
  getServiceTypes,
} from "@/api/serviechall";
import Editor from "@/components/Editor";
import imageUpload from "@/components/imageUpload";

export default {
  components: { Editor, imageUpload },
  data() {
    return {
      formData: {
        countyCode: "",
        cityCode: "",
        provinceCode: 43,
        townCode: "",
        villageCode: "",
        companyName: "",
        phone: "",
        legalPerson: "",
        orgType: "",
        contacts: "",
        runState: "",
        picUrl: "",
        province: "湖南省",
        village: "",
        town: "",
        county: "",
        city: "",
      },
      cmsOrganizationCapacity: {
        workCapacityDesc: "",
        identityCardFrontUrl: "",
        longitude: "",
        staffNum: "",
        turnoverYear: "",
        serviceObjectNum: "",
        serviceSmallFarmersNum: "",
        serviceAreaNum: "",
        serviceGrazieryNum: "",
        serviceOtherYear: "",
        serviceVariety: "",
        serviceType: "",
      },
      code: "",
      workCapacityDesc: "",
      areaList: [],
      cityList: [],
      streetCity: [],
      villageList: [],
      typeList: [],
      serviceTypeArray: [],
      checkedTypeArray: [],
      serviceVarietyList: [],
      checkedVarietyList: [],
      isSendCode: false,
      sendTime: 60,
      setTimeoutEnteval: null,
      isShowOther: false,
    };
  },
  methods: {
    getPlace() {
      const { provinceCode, cityCode, countyCode, townCode } = this.formData;
      let query = {
        areaCode: countyCode,
        provinceCode,
        cityCode,
        streetCode: townCode,
      };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
          this.streetCity = data.streetList ? data.streetList : [];
          this.villageList = data.villageList ? data.villageList : [];
        }
      });
    },
    dealPlace(code) {
      if (code == 1) {
        this.formData.countyCode = "";
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 2) {
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 3) {
        this.formData.villageCode = "";
      }
      this.getPlace();
    },
    getDictData() {
      getDictData({ dictType: "sys_oms_service_org_type" }).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.typeList = data;
        }
      });
    },
    sendCode() {
      let veriType = 0;
      let phoneRule =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let codeReg = /^[0-9]{4,6}$/;
      if (!phoneRule.test(this.formData.phone)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }
      // if (codeReg.test(this.code)) {
      //   veriType = 1;
      // }
      let query = { phone: this.formData.phone, veriType };
      this.sendPhoneVeriCode(query);
    },
    startTimeout() {
      this.setTimeoutEnteval = setInterval(() => {
        if (this.sendTime !== 0) {
          this.sendTime--;
        } else {
          clearTimeout(this.setTimeoutEnteval);
          this.isSendCode = false;
          this.sendTime = 60;
        }
      }, 1200);
    },
    sendPhoneVeriCode(query) {
      sendPhoneVeriCode(query).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success("验证码发送成功");
          this.isSendCode = true;
          this.startTimeout();
        } else {
          this.$message.error(msg || "验证码发送失败");
        }
      });
    },
    resetForm() {
      Object.assign(this._data, this.$options.data());
      this.getPlace();
      this.getDictData();
    },
    checkRule() {
      const {
        orgType,
        companyName,
        phone,
        contacts,
        legalPerson,
        runState,
        provinceCode,
        cityCode,
        townCode,
        villageCode,
        picUrl,
      } = this.formData;
      let phoneRule =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let cardRule =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let codeRule = /^[0-9]{4,6}$/;
      let numReg = /^[1-9][0-9]{0,9}$/;
      if (!companyName) {
        this.$message.error("主体名称不能为空");
        return;
      } else if (!orgType) {
        this.$message.error("请选择组织类型");
        return;
      } else if (!contacts) {
        this.$message.error("请输入联系人姓名");
        return;
      } else if (!phoneRule.test(phone)) {
        this.$message.error("联系人电话号码不正确");
        return;
      } else if (!codeRule.test(this.code)) {
        this.$message.error("请输入正确的验证码");
        return;
      } else if (!legalPerson) {
        this.$message.error("请输入法人代表");
        return;
      } else if (!runState) {
        this.$message.error("请选择经营状态");
        return;
      }
      if (!this.isShowOther) {
        this.getAreaStr();
        this.addOrganization();
        return;
      } else if (!picUrl) {
        this.$message.error("请上传营业执照");
        return;
      }
      this.cmsOrganizationCapacity.serviceType = this.checkedTypeArray
        .sort()
        .join(",");
      if (!this.cmsOrganizationCapacity.serviceType) {
        this.$message.error("请选择服务品种");
        return;
      }
      this.cmsOrganizationCapacity.serviceVariety = this.checkedVarietyList
        .sort()
        .join(",");
      if (!this.cmsOrganizationCapacity.serviceVariety) {
        this.$message.error("请选择服务类型");
        return;
      }
      const {
        staffNum,
        turnoverYear,
        serviceObjectNum,
        serviceSmallFarmersNum,
        serviceAreaNum,
        serviceOtherYear,
      } = this.cmsOrganizationCapacity;
      if (!numReg.test(staffNum)) {
        this.$message.error("从业人数应该是一个正整数");
        return;
      } else if (!numReg.test(turnoverYear)) {
        this.$message.error("年服务营业收入应该是一个正整数");
        return;
      } else if (!numReg.test(serviceObjectNum)) {
        this.$message.error("服务对象数应该是一个正整数");
        return;
      } else if (!numReg.test(serviceSmallFarmersNum)) {
        this.$message.error("服务小农户数应该是一个正整数");
        return;
      } else if (!numReg.test(serviceAreaNum)) {
        this.$message.error("年服务面积应该是一个正整数");
        return;
      } else if (!numReg.test(serviceOtherYear)) {
        this.$message.error("其他行业服务总量应该是一个正整数");
        return;
      }
      this.cmsOrganizationCapacity.workCapacityDesc = window.btoa(
        unescape(encodeURIComponent(this.workCapacityDesc))
      );
      this.getAreaStr();
      this.addOrganization();
    },
    getAreaStr() {
      this.cityList.map((item) => {
        if (item.code == this.formData.cityCode) {
          this.formData.city = item.name;
          return;
        }
      });
      this.areaList.map((item) => {
        if (item.code == this.formData.countyCode) {
          this.formData.county = item.name;
          return;
        }
      });
      this.streetCity.map((item) => {
        if (item.code == this.formData.townCode) {
          this.formData.town = item.name;
          return;
        }
      });
      this.villageList.map((item) => {
        if (item.code == this.formData.villageCode) {
          this.formData.village = item.name;
          return;
        }
      });
    },
    addOrganization() {
      let query = {
        cmsOrganization: this.formData,
        cmsOrganizationCapacity: this.cmsOrganizationCapacity,
        code: this.code,
      };
      const workCapacityDesc = this.cmsOrganizationCapacity.workCapacityDesc;
      if (workCapacityDesc) {
        query.cmsOrganizationCapacity.workCapacityDesc = window.btoa(
          unescape(encodeURIComponent(workCapacityDesc))
        );
      }
      addOrganization(query).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success("注册成功,请您重新登录");
          this.$router.push("/portal/login");
        } else {
          this.$message.error(msg || "注册失败");
        }
      });
    },
    serviceHall() {
      let query = {
        seachData: "",
        ascDesType: 1,
        cityCode: "",
        coreId: "",
        countyCode: "",
        dayNum: "",
        orderType: "",
        pageNum: 1,
        pageSize: 12,
        params: {},
        provinceCode: "",
        searchValue: "",
        coreId: "",
        townCode: "",
        varietyId: "",
        villageCode: "",
        isShow: false,
        serviceTypeId: "",
      };
      serviceHall(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          const { ccoreBusiness } = data;
          this.serviceTypeArray = ccoreBusiness;
        }
      });
    },
    changeServiceType() {
      getServiceTypes(this.checkedTypeArray).then((res) => {
        const { data, code } = res;
        if (code === 200) {
          if (data) {
            this.serviceVarietyList = data;
            let arr = [];
            data.forEach((item1) => {
              this.checkedVarietyList.forEach((item2) => {
                if (item1.id == item2) {
                  arr.push(item2);
                }
              });
            });
            this.checkedVarietyList = arr;
          }
        }
      });
    },
    changeserviceVariety() {},
  },
  mounted() {
    this.getPlace();
    this.getDictData();
    this.serviceHall();
  },
  beforeDestroy() {
    clearTimeout(this.setTimeoutEnteval);
  },
};
</script>
<style lang="scss" scoped>
.serviceSubject {
  width: 100%;
  .formContent {
    margin-top: 20px;
    width: 100%;
    border: 15px solid #f5f2f9;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    .fc-title {
      margin: 20px;
      font-size: 20px;
      font-weight: 600;
      position: relative;
      .fct-text {
        position: relative;
        z-index: 9999;
        background-color: #fff;
        margin-left: 20px;
        color: #000;
        padding: 0 8px;
      }
    }
    .fc-title:after {
      content: "";
      border-top: 1px solid #ddd;
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      z-index: 9;
    }
    .fc-box {
      display: flex;
      justify-content: space-between;
    }
    .phoneItem {
      display: flex;
      width: 560px;
      justify-content: space-between;
      /deep/ .el-input--small .el-input__inner {
        width: 140px !important;
      }
      /deep/ .el-form-item__content {
        display: flex;
        justify-content: space-between;
        width: 440px;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
    /deep/ .el-select {
      margin-right: 20px;
    }
    .selectContent {
      /deep/ .el-input--small .el-input__inner {
        width: 200px !important;
      }
    }
    .lb-slot {
      display: flex;
      justify-content: flex-end;
    }
    /deep/ .el-input--small .el-input__inner {
      width: 420px;
      height: 36px;
    }
    /deep/ .el-button {
      margin-left: 10px;
      height: 36px;
      background-color: #17a763;
      color: #fff;
    }
    /deep/ .el-upload-dragger {
      width: 140px;
      height: 100px;
    }
    /deep/ .el-upload-dragger .el-icon-upload {
      margin: 6px 0 0 0;
    }
  }
  .footerBtn {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    .el-button {
      height: 36px;
      color: #fff;
    }
  }
}
</style>