<template>
  <div class="villageCollective">
    <div class="formContent">
      <div class="fc-title">
        <span class="fct-text">基本信息</span>
      </div>
      <el-form
        inline
        :model="formData"
        label-width="200px"
        label-position="right"
      >
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>村集体名称</span>
              </div>
            </template>
            <el-input
              v-model="formData.name"
              placeholder="请输入村集体名称"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>手机号</span>
              </div>
            </template>
            <el-input
              v-model="formData.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>验证码</span>
              </div>
            </template>
            <el-input v-model="code" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-button v-if="!isSendCode" @click="sendCode">发送验证码</el-button>
          <el-button v-if="isSendCode">{{ `重新发送(${sendTime})` }}</el-button>
        </div>
        <div class="selectContent">
          <el-form-item label="所在区域">
            <el-select
              @change="dealPlace(1)"
              v-model="formData.cityCode"
              placeholder="市"
            >
              <el-option
                v-for="item in cityList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select
              @change="dealPlace(2)"
              v-model="formData.countyCode"
              placeholder="区/县"
            >
              <el-option
                v-for="item in areaList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select
              @change="dealPlace(3)"
              v-model="formData.townCode"
              placeholder="乡/镇"
            >
              <el-option
                v-for="item in streetCity"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option> </el-select
            ><el-select v-model="formData.villageCode" placeholder="村">
              <el-option
                v-for="item in villageList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- <div>
          <el-form-item label="农村集体经济组织登记证">
            <imageUpload :limit="1" v-model="formData.imgUrl" />
          </el-form-item>
        </div> -->
      </el-form>
    </div>
    <div class="footerBtn">
      <el-button @click="checkRule" type="success" icon="el-icon-check"
        >确定</el-button
      >
      <el-button @click="resetForm" type="danger" icon="el-icon-refresh"
        >重置</el-button
      >
    </div>
  </div>
</template>
<script>
import { getPlace, sendPhoneVeriCode, addVillage } from "@/api/serviechall";
import imageUpload from "@/components/imageUpload";
export default {
  components: {
    imageUpload,
  },
  data() {
    return {
      formData: {
        countyCode: "",
        cityCode: "",
        provinceCode: "43",
        townCode: "",
        villageCode: "",
        name: "",
        phone: "",
        // imgUrl: "",
        province: "湖南省",
        village: "",
        town: "",
        county: "",
        city: "",
      },
      code: "",
      isSendCode: false,
      sendTime: 60,
      setTimeoutEnteval: null,
      areaList: [],
      cityList: [],
      streetCity: [],
      villageList: [],
    };
  },
  methods: {
    getPlace() {
      const { provinceCode, cityCode, countyCode, townCode } = this.formData;
      let query = {
        areaCode: countyCode,
        provinceCode,
        cityCode,
        streetCode: townCode,
      };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
          this.streetCity = data.streetList ? data.streetList : [];
          this.villageList = data.villageList ? data.villageList : [];
        }
      });
    },
    dealPlace(code) {
      if (code == 1) {
        this.formData.countyCode = "";
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 2) {
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 3) {
        this.formData.villageCode = "";
      }
      this.getPlace();
    },
    sendCode() {
      let veriType = 0;
      let phoneRule =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let codeReg = /^[0-9]{4,6}$/;
      if (!phoneRule.test(this.formData.phone)) {
        this.$message.error("请输入正确的手机号码");
        return;
      }
      // if (codeReg.test(this.code)) {
      //   veriType = 1;
      // }
      let query = { phone: this.formData.phone, veriType };
      this.sendPhoneVeriCode(query);
    },
    startTimeout() {
      this.setTimeoutEnteval = setInterval(() => {
        if (this.sendTime !== 0) {
          this.sendTime--;
        } else {
          clearTimeout(this.setTimeoutEnteval);
          this.isSendCode = false;
          this.sendTime = 60;
        }
      }, 1200);
    },
    sendPhoneVeriCode(query) {
      sendPhoneVeriCode(query).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success("验证码发送成功");
          this.isSendCode = true;
          this.startTimeout();
        } else {
          this.$message.error(msg || "验证码发送失败");
        }
      });
    },
    checkRule() {
      let phoneRule =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let codeRule = /^[0-9]{4,6}$/;
      if (!this.formData.name) {
        this.$message.error("村集体名称不能为空");
        return;
      } else if (!phoneRule.test(this.formData.phone)) {
        this.$message.error("手机号码格式不正确");
        return;
      } else if (!codeRule.test(this.code)) {
        this.$message.error("请输入正确的验证码");
        return;
      }
      this.getAreaStr();
      this.addVillage();
    },
    getAreaStr() {
      this.cityList.map((item) => {
        if (item.code == this.formData.cityCode) {
          this.formData.city = item.name;
          return;
        }
      });
      this.areaList.map((item) => {
        if (item.code == this.formData.countyCode) {
          this.formData.county = item.name;
          return;
        }
      });
      this.streetCity.map((item) => {
        if (item.code == this.formData.townCode) {
          this.formData.town = item.name;
          return;
        }
      });
      this.villageList.map((item) => {
        if (item.code == this.formData.villageCode) {
          this.formData.village = item.name;
          return;
        }
      });
    },
    addVillage() {
      let query = {
        villageCollective: this.formData,
        code: this.code,
      };
      addVillage(query).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success("注册成功,请您重新登录");
          this.$router.push("/portal/login");
        } else {
          this.$message.error(msg || "注册失败");
        }
      });
    },
    submitForm() {},
    resetForm() {
      Object.assign(this._data, this.$options.data());
      this.getPlace();
    },
  },
  mounted() {
    this.getPlace();
  },
  beforeDestroy() {
    clearTimeout(this.setTimeoutEnteval);
  },
};
</script>
<style lang="scss" scoped>
.villageCollective {
  width: 100%;
  .formContent {
    margin-top: 20px;
    width: 100%;
    border: 15px solid #f5f2f9;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    .fc-title {
      margin: 20px;
      font-size: 20px;
      font-weight: 600;
      position: relative;
      .fct-text {
        position: relative;
        z-index: 9999;
        background-color: #fff;
        margin-left: 20px;
        color: #000;
        padding: 0 8px;
      }
    }
    .fc-title:after {
      content: "";
      border-top: 1px solid #ddd;
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      z-index: 9;
    }
    /deep/ .el-select {
      margin-right: 20px;
    }
    .selectContent {
      /deep/ .el-input--small .el-input__inner {
        width: 200px !important;
      }
    }
    .lb-slot {
      display: flex;
      justify-content: flex-end;
    }
    /deep/ .el-input--small .el-input__inner {
      width: 420px;
      height: 36px;
    }
    /deep/ .el-button {
      margin-left: 10px;
      height: 36px;
      background-color: #17a763;
      color: #fff;
    }
    /deep/ .el-upload-dragger {
      width: 140px;
      height: 100px;
    }
    /deep/ .el-upload-dragger .el-icon-upload {
      margin: 6px 0 0 0;
    }
  }
  .footerBtn {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    .el-button {
      height: 36px;
      color: #fff;
    }
  }
}
</style>